import React from 'react'

export const GatsbyGraphic = () => {
  return (
    <>
      <path
        d="M500,860.85a59.07,59.07,0,1,0,59.07,59.07A59.24,59.24,0,0,0,500,860.85Zm-32.91,92a45.79,45.79,0,0,1-13.5-32.07l46,45.57C487.76,965.91,476,961.69,467.09,952.83Zm43,12.23L454.86,909.8a46.37,46.37,0,0,1,82.69-17.3L531.22,898A39,39,0,0,0,500,882a38.38,38.38,0,0,0-35.86,25.31l48.52,48.52a38.26,38.26,0,0,0,24.47-27.42H516.88v-8.44h29.53A46.54,46.54,0,0,1,510.13,965.06Z"
        fill="none"
        stroke="#fff"
        strokeWidth="16px"
      />
      <path
        d="M500,860.85a59.07,59.07,0,1,0,59.07,59.07A59.24,59.24,0,0,0,500,860.85Zm-32.91,92a45.79,45.79,0,0,1-13.5-32.07l46,45.57C487.76,965.91,476,961.69,467.09,952.83Zm43,12.23L454.86,909.8a46.37,46.37,0,0,1,82.69-17.3L531.22,898A39,39,0,0,0,500,882a38.38,38.38,0,0,0-35.86,25.31l48.52,48.52a38.26,38.26,0,0,0,24.47-27.42H516.88v-8.44h29.53A46.54,46.54,0,0,1,510.13,965.06Z"
        fill="#663795"
      />
    </>
  )
}
