import React from 'react'
import { useTheme } from '../../themeContext'

export const NormalEyebrows = () => {
  const { colors } = useTheme()

  return (
    <>
      <path
        d="M600.89,460.57a318.14,318.14,0,0,1,33.51-6.65l-2.66.35c13.18-1.74,26.54-2.25,39.75-.48l-2.66-.36c3.27.46,6.54,1,9.82,1.42l-2.66-.36a221,221,0,0,1,29.48,6.11c5,1.39,11.1-1.73,12.3-7a10.19,10.19,0,0,0-7-12.3,239.21,239.21,0,0,0-34.59-6.78l2.66.36a143,143,0,0,0-33.44-1.95,235.08,235.08,0,0,0-33.09,4.55c-5.62,1.13-11.2,2.43-16.76,3.8-5.08,1.25-8.62,7.26-7,12.3a10.19,10.19,0,0,0,12.3,7Z"
        fill={colors.outline}
      />
      <path
        d="M286.14,460.6a202.56,202.56,0,0,1,29.25-6.19l-2.66.36a195.53,195.53,0,0,1,51.38.08l-2.66-.35a218.94,218.94,0,0,1,29.45,6.1c5,1.4,11.1-1.73,12.3-7a10.19,10.19,0,0,0-7-12.3A227.26,227.26,0,0,0,352,433.6a208.27,208.27,0,0,0-71.22,7.71,10.39,10.39,0,0,0-6,4.6,10.2,10.2,0,0,0-1,7.7c1.65,5.11,6.91,8.54,12.3,7Z"
        fill={colors.outline}
      />
    </>
  )
}
