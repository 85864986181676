import React from 'react'
import { colors } from '../../theme'

export const RedwoodGraphic = () => {
  return (
    <>
      <rect x="441.15" y="860.49" width="112" height="112" fill="none" />
      <rect x="433.91" y="856" width="132.18" height="132.18" fill="none" />
      <path
        d="M490.59,859c-4.7,2.37-14.62,7-20,10.65s-4.7,7.1-7.05,9.47-9.4,8.28-14.1,11.83-3.53,13-4.7,16.56-3.53,11.83-4.71,15.38,2.35,8.28,4.71,13,8.22,17.74,10.57,22.48,9.41,2.36,15.28,7.09a206.67,206.67,0,0,0,20,14.2c5.87,3.55,8.23,3.55,14.1,0a205.35,205.35,0,0,0,20-14.2c5.88-4.73,12.93-2.36,15.29-7.09s8.22-17.75,10.57-22.48,5.88-9.47,4.71-13-3.53-11.83-4.71-15.38,0-13-4.7-16.56a174,174,0,0,1-14.1-11.83c-4-3.77-2.35-5.92-7.06-9.47s-15.28-8.28-20-10.65A14.93,14.93,0,0,0,490.59,859Z"
        fillRule="evenodd"
        fill={colors.white}
      />
      <path
        d="M474,879.46l22,15a3.14,3.14,0,0,0,1.68.52,3,3,0,0,0,1.67-.52l22-15.07a3,3,0,0,0-.41-5.13l-22-10.9a3.05,3.05,0,0,0-2.64,0l-21.94,10.9a3,3,0,0,0-.36,5.18Zm31.14,19.73a3,3,0,0,0,1.3,2.49l17.64,12.06a3,3,0,0,0,3.66-.24l14.79-13.23a3,3,0,0,0-.13-4.6l-14.12-11.29a3,3,0,0,0-3.55-.14l-18.29,12.51A3,3,0,0,0,505.13,899.19Zm-43.87,16.4a3,3,0,0,1,1,2.54,3,3,0,0,1-1.49,2.29l-10.53,6.31a3,3,0,0,1-3.39-.23,3,3,0,0,1-1-3.27l3.9-12.25a3,3,0,0,1,2.05-2,2.92,2.92,0,0,1,2.78.66Zm57.13,1.28-19.05-13a3,3,0,0,0-3.34,0l-19,13a3,3,0,0,0-1.3,2.29,3.13,3.13,0,0,0,1,2.46l19,17a2.94,2.94,0,0,0,2,.75,3,3,0,0,0,2-.75l19-17a3,3,0,0,0,1-2.46A3,3,0,0,0,518.39,916.87ZM467.6,913.5l-14.78-13.23a3.07,3.07,0,0,1-1-2.37,3,3,0,0,1,1.12-2.28l14.12-11.36a3,3,0,0,1,3.57-.13l18.27,12.5a3.05,3.05,0,0,1,1.36,2.53,3,3,0,0,1-1.36,2.52l-17.62,12.06A3,3,0,0,1,467.6,913.5Zm75.88,19-15.05-9a3,3,0,0,0-3.51.34l-18.39,16.41a3,3,0,0,0,.87,5l25.51,10.34a3,3,0,0,0,3.85-1.57l7.9-17.71A3,3,0,0,0,543.48,932.51ZM545.57,911l3.89,12.25h0a3,3,0,0,1-2.86,3.93,2.91,2.91,0,0,1-1.52-.43l-10.55-6.31a2.93,2.93,0,0,1-1.44-2.29,3,3,0,0,1,1-2.54l6.69-6a3,3,0,0,1,2.77-.66A3,3,0,0,1,545.57,911Zm-55.8,32a3,3,0,0,0-1-2.76l-18.39-16.41a3,3,0,0,0-3.51-.34l-15,9a3.05,3.05,0,0,0-1.2,3.84l7.92,17.71a3,3,0,0,0,3.84,1.56l25.5-10.33A3,3,0,0,0,489.77,943Zm9,5,20.46,8.29a3,3,0,0,1,.54,5.27l-20.48,14.22a2.94,2.94,0,0,1-1.67.54,3,3,0,0,1-1.67-.54l-20.46-14.22a3,3,0,0,1,.58-5.27l20.46-8.29A3.09,3.09,0,0,1,498.79,948.06Z"
        fill="#c04927"
        fillRule="evenodd"
      />
    </>
  )
}
