import React from 'react'
import { useTheme } from '../../themeContext'
import { HairProps } from './types'

export const Back = () => <></>

export const Front = ({ hairColor }: HairProps) => {
  const { colors, skin } = useTheme()

  const { base } = colors.hair[hairColor]

  return (
    <>
      <path
        d="M425.22,300.35a148.61,148.61,0,0,1,35.91-15A151.13,151.13,0,0,1,500,280,145.16,145.16,0,0,1,539,285.08a138.61,138.61,0,0,1,35.82,15.27,315.9,315.9,0,0,0-37.19-9.13A210.64,210.64,0,0,0,500.05,288a222,222,0,0,0-37.55,3.48A366.93,366.93,0,0,0,425.22,300.35Z"
        fill={colors.outline}
      />
      <path
        d="M425.22,328.3a148.61,148.61,0,0,1,35.91-15A151.56,151.56,0,0,1,500,308,145.15,145.15,0,0,1,539,313a138.61,138.61,0,0,1,35.82,15.27,317.89,317.89,0,0,0-37.19-9.13,210.64,210.64,0,0,0-37.54-3.23,222,222,0,0,0-37.55,3.48A369.62,369.62,0,0,0,425.22,328.3Z"
        fill={colors.outline}
      />
      <path
        d="M574.78,328.43a310.55,310.55,0,0,1-37.2,5.68Q518.85,335.91,500,336c-12.55,0-25.1-.47-37.6-1.64a271,271,0,0,1-37.19-5.91c12.59-.39,25-.34,37.49-.37L500,328l37.29-.13C549.73,327.87,562.2,327.86,574.78,328.43Z"
        fill={skin.shadow}
      />
      <path
        d="M574.78,300.47a330.88,330.88,0,0,1-37.24,5.22q-18.72,1.63-37.53,1.71c-12.53.05-25.06-.42-37.56-1.48a288,288,0,0,1-37.23-5.45c12.57-.59,25-.7,37.47-.84L500,299.4c12.42,0,24.85-.06,37.31,0S562.22,299.7,574.78,300.47Z"
        fill={skin.shadow}
      />
      <path
        d="M871.11,500c-94.26-5.93-88.36-172.82-144.1-140.14h0l.42.69c-1.08,8.7-11.09,100.12,26.09,224.1.41-1.25.81-2.51,1.21-3.77,2,5.4,17,39.1,75.81,23.16-40.58-34.22-31.47-75.27-31.47-75.27S843.62,530.09,871.11,500Z"
        fill={base}
      />
      <path
        d="M871.11,500c-94.26-5.93-88.36-172.82-144.1-140.14h0l.42.69c-1.08,8.7-11.09,100.12,26.09,224.1.41-1.25.81-2.51,1.21-3.77,2,5.4,17,39.1,75.81,23.16-40.58-34.22-31.47-75.27-31.47-75.27S843.62,530.09,871.11,500Z"
        fill="none"
        stroke={colors.outline}
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <path
        d="M766.75,500a121.9,121.9,0,0,1,1.52,21.66,210.12,210.12,0,0,1-1.6,21.69q-.7,5.4-1.62,10.78c-.65,3.57-1.35,7.14-2.16,10.69a190.76,190.76,0,0,1-6,21l-7.59-2.54c2.11-6.73,4.13-13.49,5.92-20.32l1.32-5.13,1.22-5.16,1.17-5.17,1.07-5.2c1.41-6.94,2.5-13.94,3.53-21S765.53,507.16,766.75,500Z"
        fill={colors.outline}
      />
      <path
        d="M730.85,358.46A199.08,199.08,0,0,1,742,378.52c.83,1.72,1.62,3.46,2.42,5.2l2.3,5.25,2.15,5.32,2,5.36a210.27,210.27,0,0,1,6.64,21.88,127.56,127.56,0,0,1,3.37,22.58c-2.76-7.11-5.29-14.18-7.94-21.19s-5.32-13.95-8.36-20.76l-2.29-5.09-2.39-5-2.45-5-2.55-4.95c-3.43-6.59-7.09-13-10.87-19.43Z"
        fill={colors.outline}
      />
      <path
        d="M128.89,500c94.26-5.93,88.36-172.82,144.1-140.14h0l-.42.69c1.08,8.7,11.09,100.12-26.09,224.1-.41-1.25-.81-2.51-1.21-3.77-2,5.4-17,39.1-75.81,23.16,40.58-34.22,31.47-75.27,31.47-75.27S156.38,530.09,128.89,500Z"
        fill={base}
      />
      <path
        d="M128.89,500c94.26-5.93,88.36-172.82,144.1-140.14h0l-.42.69c1.08,8.7,11.09,100.12-26.09,224.1-.41-1.25-.81-2.51-1.21-3.77-2,5.4-17,39.1-75.81,23.16,40.58-34.22,31.47-75.27,31.47-75.27S156.38,530.09,128.89,500Z"
        fill="none"
        stroke={colors.outline}
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <path
        d="M233.25,500c1.22,7.16,2.18,14.24,3.24,21.28s2.12,14.05,3.53,21l1.07,5.2,1.17,5.17,1.22,5.16,1.32,5.13c1.79,6.83,3.8,13.59,5.92,20.32l-7.59,2.54a190.76,190.76,0,0,1-6-21c-.81-3.55-1.51-7.12-2.16-10.69s-1.15-7.18-1.62-10.78a210.12,210.12,0,0,1-1.6-21.69A121.9,121.9,0,0,1,233.25,500Z"
        fill={colors.outline}
      />
      <path
        d="M276,362.65c-3.78,6.39-7.44,12.84-10.87,19.43L262.55,387l-2.45,5-2.39,5-2.29,5.09c-3,6.81-5.74,13.76-8.36,20.76s-5.18,14.08-7.94,21.19a127.56,127.56,0,0,1,3.37-22.58,210.27,210.27,0,0,1,6.64-21.88l2-5.36L253.3,389l2.3-5.25c.8-1.74,1.59-3.48,2.42-5.2a199.08,199.08,0,0,1,11.13-20.06Z"
        fill={colors.outline}
      />
      <path
        d="M198.29,528.85l-1.6-15.59c-.26-2.66-.59-5.32-.79-8-.07-1.36-.15-2.72-.11-4.13a28.85,28.85,0,0,1,.3-4.29,30.11,30.11,0,0,1,4.45,7.27c.57,1.27,1.06,2.57,1.51,3.88s.88,2.63,1.29,4,.76,2.67,1.1,4,.63,2.72.89,4.09.49,2.75.65,4.15a36.91,36.91,0,0,1,.31,4.34Z"
        fill={colors.outline}
      />
      <path
        d="M793.65,527.37a36.62,36.62,0,0,1,.88-6.66c.24-1.07.53-2.14.82-3.2s.66-2.11,1-3.15a39.45,39.45,0,0,1,2.68-6.07,17.68,17.68,0,0,1,4.45-5.25,33.5,33.5,0,0,1,.11,6.68c-.09,1-.18,2.09-.31,3.1L803,515.9l-.63,6.1c-.22,2-.43,4.09-.72,5.93Z"
        fill={colors.outline}
      />
    </>
  )
}

export const hatScale = 0
